import React from "react"
import cx from "classnames"

import Check from "~/ui/identity/icon/check"
import Cross from "~/ui/identity/icon/cross"
import TitledSection from "~/ui/layout/titled-section"

type Props = {
  title: string
  description?: string
  pros: string[]
  cons: string[]
}

function Assessment({ title, description, pros, cons }: Props) {
  return (
    <TitledSection title={title} description={description} background="cyan">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <List title="Pros" type="check" items={pros} />
        <List title="Cons" type="cross" items={cons} />
      </div>
    </TitledSection>
  )
}

type ListProps = {
  items: string[]
  title: string
  type: string
  className?: string
}

function List({ title, type, items, className }: ListProps) {
  return (
    <div className={cx("p-4 lg:py-8 lg:px-5 bg-white rounded-2xl", className)}>
      <div className="mb-4 lg:mb-8 text-2xl lg:text-4xl font-bold text-font-1 leading-none">
        {title}
      </div>
      <div>
        {items.map((item, index) => {
          return (
            <div key={index} className="mb-4 last:mb-0 flex gap-4 items-start">
              {type == "cross" && (
                <Cross width={24} className="flex-shrink-0" />
              )}
              {type == "check" && (
                <Check width={24} className="flex-shrink-0" />
              )}
              <p className="text-lg text-font-1">{item}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Assessment
