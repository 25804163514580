import React from "react"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import CasinoCollection from "~/models/casino-collection"
import Casino from "~/models/casino"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import ChecklistBlock from "~/ui/compositions/generic/checklist"
import CasinoToplistBlock from "~/ui/compositions/casino/toplist"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import ParagraphsBlock from "../compositions/generic/paragraphs"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import StepsBlock from "../compositions/generic/steps"
import BlacklistBlock from "~/ui/compositions/casino/blacklist"
import SequenceBlock from "~/ui/compositions/generic/sequence"
import AssessmentBlock from "~/ui/compositions/generic/assessment"
import ButtonBlock from "../compositions/generic/button"

import { graphql } from "gatsby"
import {
  PaymentPageQuery,
  ContentfulCasinoCollection,
  ContentfulPage,
  ContentfulCasino,
} from "../../../types/graphql-types"

export const query = graphql`
  query PaymentPage($id: String, $slug: String) {
    contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    contentfulCasinoCollection(type: { eq: $slug }) {
      ...CasinoCollectionFields
    }
    allContentfulCasino(filter: { rating: { eq: 1 } }, limit: 3) {
      nodes {
        ...CasinoFields
      }
    }
  }
`

type Props = {
  data: PaymentPageQuery
}

const PaymentPage = ({ data }: Props) => {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const casinoCollection = new CasinoCollection(
    data.contentfulCasinoCollection as ContentfulCasinoCollection
  )
  const blacklistCasinos = data.allContentfulCasino.nodes.map(casino => {
    return new Casino(casino as ContentfulCasino)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && 
            <React.Fragment>
              <PageCoverBlock {...block.props} />
              <div className="mb-2 mt-2">
                <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                  <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                </div>
              </div>
            </React.Fragment>
            }
            {block.name == "CasinoToplist" && (
              <CasinoToplistBlock
                rank
                collection={casinoCollection}
                {...block.props}
              />
            )}
            {block.name == "Button" && <ButtonBlock {...block.props} />}
            {block.name == "Checklist" && <ChecklistBlock {...block.props} />}
            {block.name == "Assessment" && <AssessmentBlock {...block.props} />}
            {block.name == "Sequence" && <SequenceBlock {...block.props} />}
            {block.name == "Blacklist" && (
              <BlacklistBlock casinos={blacklistCasinos} {...block.props} />
            )}
            {block.name == "Steps" && <StepsBlock {...block.props} />}
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default PaymentPage
