import React from "react"

import Image from "~/ui/elements/image/image"
import Stars from "~/ui/elements/review/stars"
import Cross from "~/ui/identity/icon/cross"
import Casino from "~/models/casino"

type Props = {
  casino: Casino
}

function BlacklistCard({ casino }: Props) {
  return (
    <div className="shadow-casino-card rounded-xl bg-white overflow-hidden">
      <div className="bg-black py-6">
        <Image
          className="block w-[180px] lg:w-[224px] mx-auto"
          gatsbyImageData={casino.logo}
          alt={casino.name}
        />
      </div>
      <div className="p-6 lg:py-5 lg:px-6">
        <div className="mb-2 lg:mb-3 text-xl text-font-1 font-medium text-center lg:text-2xl">
          {casino.name}
        </div>
        <div className="mb-3 lg:mb-6 mx-auto flex gap-2 justify-center items-center">
          <Stars rating={casino.rating} color="#FF4757" />
          <div className="text-lg text-font-2">{casino.rating}</div>
        </div>
        {casino.cons.map((con, index) => {
          return (
            <div
              key={index}
              className="flex mb-3 lg:mb-5 last:mb-0 items-start"
            >
              <Cross
                width={22}
                className="mr-3 pt-1 lg:pt-0 flex-shrink-0 w-[18px] lg:w-[22px]"
              />
              <span className="text-sm lg:text-base text-font-1">{con}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BlacklistCard
