import React from "react"

import TitledSection from "~/ui/layout/titled-section"
import Casino from "~/models/casino"
import BlacklistCard from "~/ui/components/casino/blacklist-card"

type Props = {
  title: string
  description: string
  casinos: Casino[]
}

function Blacklist({ title, description, casinos }: Props) {
  return (
    <TitledSection title={title} description={description}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-10">
        {casinos.map((casino: Casino) => {
          return <BlacklistCard key={casino.id} casino={casino} />
        })}
      </div>
    </TitledSection>
  )
}

export default Blacklist
