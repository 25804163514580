import React from "react"
import cx from "classnames"

import sanitize from "~/helpers/sanitize"
import Button from "~/ui/elements/button/link"

type Props = {
  className?: string
  title?: string
  description?: string
  button: ButtonProps
}

type ButtonProps = {
  label: string
  link: string
}

function ButtonBlock({ className, title, description, button }: Props) {
  return (
    <section
      className={cx("overflow-hidden py-12 md:py-20 bg-blue-500", className)}
    >
      <div className="relative max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
        {title && (
          <div className="relative mb-8 z-10 lg:px-20 xl:px-52">
            <h2
              className="text-2xl font-bold text-font-1 lg:text-4xl text-center tracking-[-0.025em]"
              dangerouslySetInnerHTML={sanitize(title)}
            ></h2>

            {description && (
              <p
                className="mt-3 text-font-2 text-lg text-center"
                dangerouslySetInnerHTML={sanitize(description)}
              ></p>
            )}
          </div>
        )}

        <div className="relative z-10 text-center">
          <Button to={button.link} type="primary">
            {button.label}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default ButtonBlock
